<google-map height="500px"
                        width="100%"
                        [center]="center"
                        [zoom]="zoom"
                        (mapClick)="moveMap($event)"
                        (mapMousemove)="move($event)">
    <ng-container *ngFor="let point of maker">
        <map-marker [position]="point"></map-marker>
    </ng-container>
</google-map>

<div>Latitude: {{display?.lat}}</div>
<div>Longitude: {{display?.lng}}</div>

