<div>
  <div>
    <form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()" class="date-range-form">
      <div class="form-group">
        <label for="startDate" class="form-label">Start Date:</label>
        <input type="date" id="startDate" formControlName="startDate" class="form-control">
      </div>
      <div class="form-group">
        <label for="startTime" class="form-label">Start Time:</label>
        <input type="time" id="startTime" formControlName="startTime" class="form-control">
      </div>
      <div class="form-group">
        <label for="endDate" class="form-label">End Date:</label>
        <input type="date" id="endDate" formControlName="endDate" class="form-control">
      </div>
      <div class="form-group">
        <label for="endTime" class="form-label">End Time:</label>
        <input type="time" id="endTime" formControlName="endTime" class="form-control">
      </div>
      <div class="form-group">
        <label for="cartID" class="form-label">cartID:</label>
        <input type="text" id="cartID" formControlName="cartID" class="form-control">
      </div>
      <button type="submit" [disabled]="dateRangeForm.invalid" class="btn btn-primary">Submit</button>
    </form>
  </div>

  <hr>

  <div class="chart-container">
    <span class="chart-title">{{cart_id}}x, y, z</span>
    <canvas id="MyChart" class="chart"></canvas>
  </div>
  <div class="chart-container" style="margin-top: 40px;">
    <span class="chart-title">{{cart_id}}Angle X, Angle Y, Angle Z</span>
    <canvas id="MyChartAngle" class="chart"></canvas>
  </div>

</div>
