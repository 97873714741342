import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
 //   pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'sensor-status',
    loadChildren: () =>
      import('./sensor-status/sensor-status.module').then(
        (m) => m.SensorStatusModule
      ),
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./report/report.module').then(
        (m) => m.ReportModule
      ),
  },
  {
    path: 'selfSearch',
    loadChildren: () =>
      import('./self-search/self-search.module').then(
        (m) => m.SelfSearchModule
      ),
  },

  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
