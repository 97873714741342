<div style="width:80%;justify-self: center;">
    <div style="padding:10px;">
        <div style="display:flex;width:100%">
            <div style="width:40%">
                <span style="text-align:left;">Message: </span>
                <span id="RefreshMessage" style="margin-left:10px"></span>
            </div>
            <div style="width:60%;text-align: right;">
                <button (click)="downloadExcel()" style="margin-left:10px;">Download Excel</button>
                <button (click)="print()" style="margin-left:10px;">Print </button>
            </div>  

        </div>  
    </div>

    <div class="table-responsive" >
        <table class="table" style="width:80%; text-align:center; margin-left:auto; margin-right:auto;" id="tabledata">
            <thead>
                <tr>
                    <td >日期:</td>
                    <td>{{fromDateRpt}} 至 {{endDateRpt}}</td>
                    <td>時段:</td>
                    <td>{{(breakfastRpt=='true')?'早餐':''}} 
                        {{(lunchRpt=='true')?'午餐':''}} 
                        {{(dinnerRpt=='true')?'晚餐':''}}
                        {{(weekDayRpt=='true')?'平日':''}}
                        {{(weekEndRpt=='true')?'假日':''}}

                    </td>
                </tr>
            </thead>
            <thead *ngIf="recordCountsBreakfast || recordCountsLunch || recordCountsDinner">
                <tr>
                    <th>時段</th>
                    <th>日期</th>
                    <th>問題數量</th>
                    <th>感應器編號</th>
                </tr>
            </thead>
            <tbody *ngIf="recordCountsBreakfast">
                <tr *ngFor="let data of recordCountsBreakfast">
                    <td>早餐</td>   
                    <td>{{data.date}}</td>
                    <td>{{data.count}}</td>
                    <td>{{data.cartIds}}</td>                    
                </tr>
                <tr *ngIf="breakfastRpt=='true'">
                    <td>早餐</td>
                    <td>總數</td>
                    <td>{{totalBreakfast()}}</td>
                    <td></td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <tbody *ngIf="recordCountsLunch">
                <tr *ngFor="let data of recordCountsLunch">
                    <td>午餐</td>   
                    <td>{{data.date}}</td>
                    <td>{{data.count}}</td>
                    <td>{{data.cartIds}}</td>                    
                </tr>
                <tr *ngIf="lunchRpt=='true'">
                    <td>午餐</td>
                    <td>總數</td>
                    <td>{{totalLunch()}}</td>
                    <td></td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <tbody *ngIf="recordCountsDinner">
                <tr *ngFor="let data of recordCountsDinner">
                    <td>晚餐</td>   
                    <td>{{data.date}}</td>
                    <td>{{data.count}}</td>
                    <td>{{data.cartIds}}</td>                    
                </tr>
                <tr *ngIf="dinnerRpt=='true'">
                    <td>晚餐</td>
                    <td>總數</td>
                    <td>{{totalDinner()}}</td>
                    <td></td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <tbody *ngIf="recordCountsWeekDay" style="display:none">
                <tr *ngFor="let data of recordCountsWeekDay">
                    <td>平日</td>   
                    <td>{{data.date}}</td>
                    <td>{{data.count}}</td>
                    <td>{{data.cartIds}}</td>                    
                </tr>
                <tr *ngIf="weekDayRpt=='true'">
                    <td>平日</td>
                    <td>總數</td>
                    <td>{{totalWeekDay()}}</td>
                    <td></td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <tbody *ngIf="recordCountsWeekEnd" style="display:none">
                <tr *ngFor="let data of recordCountsWeekEnd">
                    <td>假日</td>   
                    <td>{{data.date}}</td>
                    <td>{{data.count}}</td>
                    <td>{{data.cartIds}}</td>                    
                </tr>
                <tr *ngIf="weekEndRpt=='true'">
                    <td>假日</td>
                    <td>總數</td>
                    <td>{{totalWeekEnd()}}</td>
                    <td></td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
                        
        </table>
    </div>
    <div class="chart-container" style="display:block;width:100%; page-break-before:always;">
        <div style="position: relative; height: 40vh; width:50%">
            <canvas  id="MyChartWeekDay"  >{{ lineChartWeekDay }}</canvas>
        </div>
        <hr>
        <div style="position: relative; height: 40vh; width:50%; margin-top:10px">
            <canvas  id="MyChartWeekEnd"  >{{ lineChartWeekEnd }}</canvas>
        </div>

    </div>
</div>
