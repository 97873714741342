import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {groupBy, sortBy} from 'lodash';
import {Chart} from 'chart.js/auto';
import {getRelativePosition} from 'chart.js/helpers';
import {std} from "mathjs";

import {SensorInfo, SensorDataMap, WorkingTime} from '../common/model';
import {DataService} from '../common/data.service';
import {getWindowData} from '../common/utils';
import {workAroudMap} from '../common/static';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})


export class ReportComponent implements OnInit {
  fromDateRpt: string = '';
  endDateRpt: string = '';
  breakfastRpt: string = 'false';
  lunchRpt: string = 'false';
  dinnerRpt: string = 'false';
  weekDayRpt: string = 'false';
  weekEndRpt: string = 'false';

  public sensorInfoBreakfast: Array<SensorInfo> = [];
  public sensorInfoLunch: Array<SensorInfo> = [];
  public sensorInfoDinner: Array<SensorInfo> = [];
  public sensorInfoWeekDay: Array<SensorInfo> = [];
  public sensorInfoWeekEnd: Array<SensorInfo> = [];
  public sensorInfo: Array<SensorInfo> = [];

  recordCountsBreakfast: any;
  recordCountsLunch: any;
  recordCountsDinner: any;
  recordCountsWeekDay: any;
  recordCountsWeekEnd: any;

  dangerLv = 25;
  dangerLv_x = 20;
  dangerLv_y = 20;
  dangerLv_z = 28;
  dangerLv_acc = 30;
  dangerlv_x_std = 5.5
  dangerlv_y_std = 5.5

  dangerAngleX = 30;
  dangerAngleY = 30;
  dangerAngleZ = 30;

  warningLv_x = 20;
  warningLv_y = 20;
  warningLv_z = 20;

  angleX = 0.00;
  angleY = 0.00;
  angleZ = 0.00;
  acceleration = 0.00;
  maxAcceleration = 0.00;
  minAcceleration = 0.00;


  public lineChartWeekDay: any = null;
  public lineChartWeekEnd: any = null;

  public timeSettingStatus = {
    breakfastStart: moment('05:00:00', 'HH:mm:ss'),
    breakfastEnd: moment('09:00:00', 'HH:mm:ss'),
    lunchStart: moment('10:00:00', 'HH:mm:ss'),
    lunchEnd: moment('14:30:00', 'HH:mm:ss'),
    dinnerStart: moment('15:00:00', 'HH:mm:ss'),
    dinnerEnd: moment('19:00:00', 'HH:mm:ss')
  };

  public timeSetting = {
    breakfastStart: moment('06:30:00', 'HH:mm:ss'),
    breakfastEnd: moment('07:15:00', 'HH:mm:ss'),
    lunchStart: moment('11:20:00', 'HH:mm:ss'),
    lunchEnd: moment('12:00:00', 'HH:mm:ss'),
    dinnerStart: moment('16:45:00', 'HH:mm:ss'),
    dinnerEnd: moment('17:30:00', 'HH:mm:ss')
  };


  constructor(private route: ActivatedRoute, private data: DataService, private router:Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.fromDateRpt = params['fromDateRpt'];
      this.endDateRpt = params['endDateRpt'];
      this.breakfastRpt = params['breakfastRpt'];
      this.lunchRpt = params['lunchRpt'];
      this.dinnerRpt = params['dinnerRpt'];
      this.weekDayRpt = params['dayRpt'];
      this.weekEndRpt = params['weekRpt'];

      console.log('fromDateRpt', this.fromDateRpt);
      console.log('endDateRpt', this.endDateRpt);
      console.log('breakfastRpt', this.breakfastRpt);
      console.log('lunchRpt', this.lunchRpt);
      console.log('dinnerRpt', this.dinnerRpt);
      console.log('dayRpt', this.weekDayRpt);
      console.log('weekRpt', this.weekEndRpt);

    });

    this.createChart();
    this.genReport();


  }

  genReport() {
    console.log('genReport');

    this.getData();

  }

  /**
   * save data to working time list, then save to weekend list or workday list
   * @param sensorDataObj
   * @param mealList
   * @param currDateTime
   */
  pushDataToList(sensorDataObj: SensorInfo, mealList: any, currDateTime: any) {
    this.sensorInfo.push(sensorDataObj);
    mealList.push(sensorDataObj);
    if (currDateTime.weekday() === 6 || currDateTime.weekday() === 0) {
      this.sensorInfoWeekEnd.push(sensorDataObj);
    } else {
      this.sensorInfoWeekDay.push(sensorDataObj);
    }
  }

  getData() {
    const refreshmsg = document.getElementById('RefreshMessage');
    let currDateTime: any;
    let currTime: any;
    if (refreshmsg)
      refreshmsg.innerHTML = 'Loading Data, Please wait...';

    this.data.myData(this.fromDateRpt, this.endDateRpt, this.router).subscribe((data: SensorDataMap) => {
      this.sensorInfoBreakfast = [];
      this.sensorInfoLunch = [];
      this.sensorInfoDinner = [];
      this.sensorInfo = [];
      this.sensorInfoWeekDay = [];
      this.sensorInfoWeekEnd = [];

      for (let [sensorName, sensorDatas] of Object.entries(data)) {
        for (let [index, sensorData] of sensorDatas.entries()) {

          currDateTime = moment((new Date(sensorData.time)));
          currTime = moment(currDateTime.format('HH:mm:ss'), 'HH:mm:ss');
          let sensorDataX = parseFloat(sensorData.x)
          let sensorDataY = parseFloat(sensorData.y)
          let sensorDataZ = parseFloat(sensorData.z)
          let sensorDataLat = parseFloat(sensorData.lat)
          let sensorDataLng = parseFloat(sensorData.lng)
          let sensorDataBattery = parseFloat(sensorData.battery_lv)
          let sensorDataCharged = sensorData.charged === "1"

          // check working zone
          let workingZone = undefined;
          if (currTime.isBetween(this.timeSettingStatus.breakfastStart, this.timeSettingStatus.breakfastEnd)) {
            workingZone = WorkingTime.BREAKFAST
          } else if (currTime.isBetween(this.timeSettingStatus.lunchStart, this.timeSettingStatus.lunchEnd)) {
            workingZone = WorkingTime.LUNCH
          } else if (currTime.isBetween(this.timeSettingStatus.dinnerStart, this.timeSettingStatus.dinnerEnd)) {
            workingZone = WorkingTime.DINNER
          }

          // algorithm
          if (workingZone) {
            // Calculate Accelerometer x,y,z to angle
            this.angleX = Math.atan2(sensorDataX, Math.sqrt(sensorDataY * sensorDataY + sensorDataZ * sensorDataZ)) * (180 / Math.PI);
            this.angleY = Math.atan2(sensorDataY, Math.sqrt(sensorDataX * sensorDataX + sensorDataZ * sensorDataZ)) * (180 / Math.PI);
            this.angleZ = Math.atan2(Math.sqrt(sensorDataX * sensorDataX + sensorDataY * sensorDataY), sensorDataZ) * (180 / Math.PI);

            // Another way to calculate the angle
            this.acceleration = Math.sqrt(Math.pow(sensorDataX, 2) + Math.pow(sensorDataY, 2) + Math.pow(sensorDataZ, 2));
            this.minAcceleration = Math.min(this.minAcceleration, this.acceleration);
            this.maxAcceleration = Math.max(this.maxAcceleration, this.acceleration);

            // get window data
            let windowData = getWindowData(sensorDatas, index)
            let dataStdX = Number(std(windowData.map(data => parseFloat(data.x))))
            let dataStdY = Number(std(windowData.map(data => parseFloat(data.y))))

            // When the total acceleration and gravitational acceleration exceeds the dangerous value
            if (Math.abs(this.acceleration) >= this.dangerLv_acc &&
              Math.abs(sensorDataZ) >= this.dangerLv_z &&
              Math.abs(dataStdX) >= this.dangerlv_x_std &&
              Math.abs(dataStdY) >= this.dangerlv_y_std
            ) {
              // determine occurred during delivery
              let delivery_diff = currTime.diff(moment(workAroudMap[sensorName][workingZone].delivery, 'HH:mm:ss'))
              let pickup_diff = currTime.diff(moment(workAroudMap[sensorName][workingZone].pickup, 'HH:mm:ss'))
              if (Math.abs(pickup_diff) < Math.abs(delivery_diff)) {
                continue
              }

              let resultObj = {
                id: sensorData.device_id,
                device_id: sensorData.device_id,
                cart_id: sensorData.cart_id,
                x: sensorDataX,
                y: sensorDataY,
                z: sensorDataZ,
                lat: sensorDataLat,
                lng: sensorDataLng,
                time: currDateTime.format('YYYY-MM-DD HH:mm:ss'),
                truck_id: sensorData.truck_id,
                battery_lv: sensorDataBattery,
                charged: sensorDataCharged,
                acceleration: this.acceleration,
                maxAcceleration: this.maxAcceleration,
                minAcceleration: this.minAcceleration,
                angleX: this.angleX,
                angleY: this.angleY,
                angleZ: this.angleZ,
                workingZone: workingZone
              }
              switch (workingZone) {
                case WorkingTime.BREAKFAST:
                  this.pushDataToList(resultObj, this.sensorInfoBreakfast, currDateTime);
                  break;
                case WorkingTime.LUNCH:
                  this.pushDataToList(resultObj, this.sensorInfoLunch, currDateTime);
                  break;
                case WorkingTime.DINNER:
                  this.pushDataToList(resultObj, this.sensorInfoDinner, currDateTime);
                  break;
              }
            }
          }
        }
      }

      // sort data by date
      this.sensorInfoBreakfast = sortBy(this.sensorInfoBreakfast, 'time')
      this.sensorInfoLunch = sortBy(this.sensorInfoLunch, 'time')
      this.sensorInfoDinner = sortBy(this.sensorInfoDinner, 'time')
      this.sensorInfoWeekDay = sortBy(this.sensorInfoWeekDay, 'time')
      this.sensorInfoWeekEnd = sortBy(this.sensorInfoWeekEnd, 'time')

      // Calculate the group by date and count
      if (this.breakfastRpt == 'true')
        this.countRecordsBreakfast();

      if (this.lunchRpt == 'true')
        this.countRecordsLunch();

      if (this.dinnerRpt == 'true')
        this.countRecordsDinner();

      if (this.weekDayRpt == 'true') {
        this.countRecordsWeekDay();
        this.genChartDataWeekDay();
      }

      if (this.weekEndRpt == 'true') {
        this.countRecordsWeekEnd();
        this.genChartDataWeekEnd();
      }

      if (refreshmsg)
        refreshmsg.innerHTML = 'Load Data Completed.';


    });


  }

  countRecordsBreakfast() {
    const recordsByDate = groupBy(this.sensorInfoBreakfast, (record) => record.time.slice(0, 10));
    this.recordCountsBreakfast = Object.entries(recordsByDate).map(([date, records]) => ({
      date,
      count: records.length,
      cartIds: [...new Set(records.map((record) => record.cart_id))],
    }));

  }

  countRecordsLunch() {
    const recordsByDate = groupBy(this.sensorInfoLunch, (record) => record.time.slice(0, 10));
    this.recordCountsLunch = Object.entries(recordsByDate).map(([date, records]) => ({
      date,
      count: records.length,
      cartIds: [...new Set(records.map((record) => record.cart_id))],
    }));

  }

  countRecordsDinner() {
    const recordsByDate = groupBy(this.sensorInfoDinner, (record) => record.time.slice(0, 10));
    this.recordCountsDinner = Object.entries(recordsByDate).map(([date, records]) => ({
      date,
      count: records.length,
      cartIds: [...new Set(records.map((record) => record.cart_id))],
    }));

  }

  countRecordsWeekDay() {
    const recordsByDate = groupBy(this.sensorInfoWeekDay, (record) => record.time.slice(0, 10));
    this.recordCountsWeekDay = Object.entries(recordsByDate).map(([date, records]) => ({
      date,
      count: records.length,
      cartIds: [...new Set(records.map((record) => record.cart_id))],
    }));

  }

  countRecordsWeekEnd() {
    const recordsByDate = groupBy(this.sensorInfoWeekEnd, (record) => record.time.slice(0, 10));
    this.recordCountsWeekEnd = Object.entries(recordsByDate).map(([date, records]) => ({
      date,
      count: records.length,
      cartIds: [...new Set(records.map((record) => record.cart_id))],
    }));

  }

  totalBreakfast() {
    let total = 0;
    if (this.recordCountsBreakfast) {
      this.recordCountsBreakfast.forEach((record: any) => {
        total += record.count;
      });
    }
    return total;
  }

  totalLunch() {
    let total = 0;
    if (this.recordCountsLunch) {
      this.recordCountsLunch.forEach((record: any) => {
        total += record.count;
      });
    }
    return total;
  }

  totalDinner() {
    let total = 0;
    if (this.recordCountsDinner) {
      this.recordCountsDinner.forEach((record: any) => {
        total += record.count;
      });
    }
    return total;
  }

  totalWeekDay() {
    let total = 0;
    if (this.recordCountsWeekDay) {
      this.recordCountsWeekDay.forEach((record: any) => {
        total += record.count;
      });
    }
    return total;
  }

  totalWeekEnd() {
    let total = 0;
    if (this.recordCountsWeekEnd) {
      this.recordCountsWeekEnd.forEach((record: any) => {
        total += record.count;
      });
    }
    return total;
  }

  downloadExcel() {
    const table = document.getElementById('tabledata');
    const tableHTML = table!.outerHTML;
    const blob = new Blob([tableHTML], {type: 'application/vnd.ms-excel'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.xls';
    link.click();
  }

  print() {
    window.print();
  }

  createChart() {

    // Create WeekDay Chart
    console.log('sensorData:', this.recordCountsWeekDay);

    this.lineChartWeekDay = new Chart("MyChartWeekDay", {
      type: 'line', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: [],
        datasets: [
          {
            label: "平日事故數",
            data: "",
            backgroundColor: 'blue'
          },

        ]
      },
      options: {
        aspectRatio: 2.5,
        events: ['click', 'mousemove'],
        onClick: (e) => {
          this.selectLine(e);
        },
        interaction: {
          mode: 'x'
        },

      }

    });

    this.lineChartWeekDay.canvas.parentNode.style.width = '100%';
    this.lineChartWeekDay.canvas.parentNode.style.height = '100%';

    console.log('lineChart:', this.lineChartWeekDay);

    // Create WeekEnd Chart
    console.log('sensorData:', this.recordCountsWeekEnd);

    this.lineChartWeekEnd = new Chart("MyChartWeekEnd", {
      type: 'line', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: [],
        datasets: [
          {
            label: "假日事故數",
            data: "",
            backgroundColor: 'blue'
          },

        ]
      },
      options: {
        aspectRatio: 2.5,
        events: ['click', 'mousemove'],
        onClick: (e) => {
          this.selectLine(e);
        },
        interaction: {
          mode: 'x'
        }
      }

    });
    console.log('lineChartWeekEnd:', this.lineChartWeekEnd);
    this.lineChartWeekEnd.canvas.parentNode.style.width = '100%';
    this.lineChartWeekEnd.canvas.parentNode.style.height = '100%';

  }

  genChartDataWeekDay() {
    // Re-gen line chart
    this.lineChartWeekDay.data.labels = this.recordCountsWeekDay.map((item: any) => {
      const date = new Date(item.date);
      return date.toLocaleString('HongKong', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    });
    this.lineChartWeekDay.data.datasets[0].data = this.recordCountsWeekDay.map((item: any) => item.count);
    this.lineChartWeekDay.update();
    this.lineChartWeekDay.render();

  }

  genChartDataWeekEnd() {
    // Re-gen line chart
    this.lineChartWeekEnd.data.labels = this.recordCountsWeekEnd.map((item: any) => {
      const date = new Date(item.date);
      return date.toLocaleString('HongKong', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    });
    this.lineChartWeekEnd.data.datasets[0].data = this.recordCountsWeekEnd.map((item: any) => item.count);
    this.lineChartWeekEnd.update();
    this.lineChartWeekEnd.render();

  }

  selectLine(e: any) {
    console.log('e', e);
    const points = getRelativePosition(e, e.chart);
    const dataX = e.chart.scales.x.getValueForPixel(points.x);
    const dataY = e.chart.scales.y.getValueForPixel(points.y);

    console.log('points', points);
    console.log('dataX', dataX);
    console.log('dataY', dataY);
    //this.readResult(e);
    //this.readR
  }


}
