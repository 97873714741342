<div class="content" role="main">
    <div class="left-area">
      <!-- Left area content goes here -->
      <div >
        <img src="assets/khlogo.png" alt="KH Cart Logo" style="margin-left:auto;margin-right:auto;display:block">
        <p style="font-weight:bold; text-align:center">Cartering Monitoring Admin Panel </p>
      </div>
      <hr>
      <div style="padding:10px;" >
          <legend>統計數據</legend>
          <input type="text" ngbDatepicker  #e="ngbDatepicker" [(ngModel)]="fromDateRpt!"/>
          <button (click)="e.toggle()" style="margin-left:10px">開始日期</button><br>
          <input type="text" ngbDatepicker  #f="ngbDatepicker" [(ngModel)]="endDateRpt!"/>
          <button (click)="f.toggle()" style="margin-left:10px">結束日期</button><br>
          <input type="checkbox" [(ngModel)]="breakfastRpt" >早餐：6:00-9:00<br>
          <input type="checkbox" [(ngModel)]="lunchRpt"  >午餐：11:00-14:30<br>
          <input type="checkbox" [(ngModel)]="dinnerRpt" >晚餐：16:00-19:00<br><br>
          <input type="checkbox" [(ngModel)]="dayRpt" >平日（星期一至五):全日<br>
          <input type="checkbox" [(ngModel)]="weekRpt" >假日（星期六，日，公眾假期):  全日<br><br>

          <button type="submit" (click)="submitRpt()" >提交</button>
          <button type="submit" (click)="jumpSelfSearch()" >其他時間</button>
      </div>
      <hr>
      <div style="margin-top:10px;padding:10px">
        <button (click)="onPrint()">列印</button><br>
        <button (click)="onDownload()" style="margin-top:10px">下載所有數據</button>
      </div>
      <hr>
      <span>註解：(括號內表示當天當前電量)</span>
      <div class="box-background">
        <span class="box box-charged" [style]="'background-color:green'">TEST (100)</span> - 正常，充電中<br>
        <span class="box box-unCharged" [style]="'background-color:green'">TEST (100)</span> - 正常，未充電<br>
        <span class="box box-charged" [style]="'background-color:red'">TEST (100)</span> - 警報，充電中<br>
        <span class="box box-unCharged" [style]="'background-color:red'">TEST (100)</span> - 警報，未充電<br>


    </div>

    </div>

<div class="right-area" id="printArea">
    <div class="right-top-area" >
      <div >
        <div class="resources">
          <!-- Loop to create 28 boxes with captions -->
          <div style="display:flex">
              <div>
                <input type="text" ngbDatepicker (dateSelect)="onDateSelect($event)" #d="ngbDatepicker" [startDate]="fromDate!"/>
                  <button (click)="d.toggle()" style="margin-left:10px">選擇日期</button><br>
              </div>
              <div style="margin-left:10px">
                  <button id="refreshButton" (click)="autoRefresh()">開始自動刷新</button>
                  <span id="RefreshMessage" style="margin-left:10px"></span>

              </div>
          </div>
          <div style="display:flex; margin-top:10px ">
            <input type="checkbox" [(ngModel)]="breakfast" >早餐：6:00-9:00<br>
            <input type="checkbox" [(ngModel)]="lunch"  style="margin-left:10px">午餐：11:00-14:30<br>
            <input type="checkbox" [(ngModel)]="dinner" style="margin-left:10px">晚餐：16:00-19:00<br>
            <input type="checkbox" [(ngModel)]="showRoute" style="margin-left:10px">顯示路程<br>

          </div>
         </div>
        </div>
        <hr>
        <div class="box-background">
            <span class="box {{ (box.charged=='0'?'box-unCharged':'box-charged')}} " *ngFor="let box of boxInfo" [id]="box.box" [style]="'background-color:'+box.boxcolor" (click)="readResult(box.box)">{{ box.box }} ({{ box.battery_lv.split(".")[0]  }})</span>
        </div>
        <hr>
    </div>
    <div class="right-bottom-area" >

        <div>
            <span>傳感器編號: {{cart_id}}</span>
            <google-map height="500px"
            width="100%"
            [center]="center"
            [zoom]="zoom"
            (mapClick)="moveMap($event)"
            (mapMousemove)="move($event)"
            mapId="a2d499fb4c27e358"
            id="map"
            >


              <ng-container *ngFor="let point of makers">
                <map-advanced-marker [position]="point.position" [content]="point.content" >

                </map-advanced-marker>

              </ng-container>
              <map-directions-renderer *ngIf="directionsResults$ | async as directions" [directions]="directions"></map-directions-renderer>


            </google-map>

            <div>Latitude: {{display?.lat}}</div>
            <div>Longitude: {{display?.lng}}</div>
       </div>
       <hr>
       <div class="chart-container">
        <span>{{cart_id}} - x,y,z</span>
        <canvas  id="MyChart"  >{{ lineChart }}</canvas>
      </div>
      <div class="chart-container" style="margin-top:40px;">
        <span>{{cart_id}} - Angle X, Angle Y, Angle Z</span>
        <canvas  id="MyChartAngle"  >{{ lineChartAngle }}</canvas>
      </div>

    </div>


  </div>
</div>
