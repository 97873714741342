<div class="main">
    <div class="bgImage"></div>
    <div class="login" >
        <img src="assets/khlogo.png" alt="KH Cart Logo" >
        <h1>Admin Panel</h1>
        <input type="text" id="username" name="username" placeholder="Username" [(ngModel)]="username" required>
        <input type="password" id="password" name="password" placeholder="Password" [(ngModel)]="password" required>
        <button (click)="login()">Login</button>
    </div>
</div>
