import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'kh-admin';
  boxes: any[] = []; // Declare the 'boxes' property as an empty array
  ngOnInit() {
    let boxcolor='green';
    for (let i = 0; i < 28; i++) {
      if (i==2) boxcolor='red';
      this.boxes.push({box:'B'+(i+1).toString().padStart(3, '0'), boxcolor:boxcolor});
      boxcolor='green';
    }

  }


  readResult(boxid: string) {
    console.log('readResult',boxid);
  }

}
