import {Component, OnInit, inject} from '@angular/core';
import {map, Observable} from 'rxjs';
import {Chart} from 'chart.js/auto';
import {getRelativePosition} from 'chart.js/helpers';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {MapAdvancedMarker, GoogleMap, MapDirectionsService} from '@angular/google-maps';
import {SwPush} from '@angular/service-worker';
import { Router } from '@angular/router';
import {std} from 'mathjs';

import {SensorInfo, WorkingTime, SensorDataMap} from '../common/model';
import {DataService} from '../common/data.service';
import {getWindowData} from '../common/utils';
import {workAroudMap} from '../common/static';


interface Message {
  notification: {
    title: string;
    data: {
      onActionClick: {
        default: {
          operation: string;
          url: string;
          title: string;
        }
      }
    }
  }
}

export interface BoxInfo {
  box: string;
  boxcolor: string;
  lat: number;
  lng: number;
  battery_lv: string;
  charged: string;
  fontcolor: string;
}

@Component({
  selector: 'app-sensor-status',
  templateUrl: './sensor-status.component.html',
  styleUrls: ['./sensor-status.component.scss']
})


export class SensorStatusComponent implements OnInit {

  readonly VAPID_PUBLIC_KEY = "BFBSoGUcjTsQfuBWzpThEEjTv2PZaAw4D7T3FrFM-QsbNLFRO7zVqy-xJ8ydFKa79557cJyRk6vkfoYJ0UrXEY4";

  calendar = inject(NgbCalendar)
  fromDate: NgbDate | null = this.calendar.getToday();

  fromDateRpt: NgbDate | null = this.calendar.getToday();
  endDateRpt: NgbDate | null = this.calendar.getToday();

  public sensorData!: Array<any>;

  public lineChart: any = null;
  public lineChartAngle: any = null;

  constructor(
    private router: Router,
    private data: DataService,
    private mapDirectionsService: MapDirectionsService,
    private swPush: SwPush,
  ) {
  }

  title = 'kh-admin';
  public sensorInfo: Array<SensorInfo> = [];
  public boxInfo: Array<BoxInfo> = [];
  public getDate: any;
  public varDate: any;
  public alarmPoints: { [key: string]: Array<SensorInfo> } = {};


  lat = 22.323489;
  lng = 114.180036;
  lnglat = [this.lng, this.lat];

  cart_id = '';

  dangerLv = 25;
  dangerLv_x = 20;
  dangerLv_y = 20;
  dangerLv_z = 28;
  dangerLv_acc = 30;
  dangerlv_x_std = 5.5;
  dangerlv_y_std = 5.5;

  dangerAngleX = 30;
  dangerAngleY = 30;
  dangerAngleZ = 30;

  warningLv_x = 20;
  warningLv_y = 20;
  warningLv_z = 20;


  angleX = 0.00;
  angleY = 0.00;
  angleZ = 0.00;
  acceleration = 0.00;
  minAcceleration = 0.00;
  maxAcceleration = 0.00;


  warningCount = 3;
  cautionLv = 15;

  breakfast = 0;
  lunch = 0;
  dinner = 0;
  showRoute = false;

  breakfastRpt = false;
  lunchRpt = false;
  dinnerRpt = false;
  dayRpt = false;
  weekRpt = false;

  googleMap: GoogleMap | undefined;

  gMap: google.maps.Map | undefined;

  mapAdvancedMarker: MapAdvancedMarker | undefined;

  mapAdvancedMarkerOptions: Array<any> = [];

  directionsResults$!: Observable<google.maps.DirectionsResult | undefined>;

  mapAdvancedMarkers = [{
    position: {
      lat: 22.323489,
      lng: 114.180036,
    },
    title: 'Marker 1',
    label: {
      color: 'blue',
      text: 'Marker 1',
    },
    options: {
      animation: google.maps.Animation.BOUNCE,
    },
  }];

  makerOptions1 = new google.maps.Marker({
    position: {
      lat: 22.323489,
      lng: 114.180036,
    },
    title: 'Marker 1',
    label: {
      color: 'blue',
      text: 'Marker 1',
    },
    icon: {
      url: 'assets/pin1.png',
      scaledSize: new google.maps.Size(50, 50),
    },
  });

  polyPath: google.maps.LatLngLiteral[] = [];

  polyOptions: google.maps.PolylineOptions = {
    strokeColor: '#40809d',
    strokeOpacity: 1,
    strokeWeight: 10,
    icons: [
      {
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        },
        offset: '100%',
      },
    ],
  };


  boxList = ['A1E', 'A1N', 'A2E', 'A2N', 'A3E', 'A3N', 'A5E', 'A5N', 'A6E', 'A6N', '2A1', '2A2', '2B1', '2B2', '3A1', '3A2', '3B1', '3B2', '4A1', '4A2', '4B1', '4B2', '5A1', '5A2', '5B1', '5B2', 'B027', 'B028'];
  SMSContact = "+85291628875;";
  SMSMessage = "Warning: Fall detected. Sensor ID: ";

  public timeSettingStatus = {
    breakfastStart: moment('05:00:00', 'HH:mm:ss'),
    breakfastEnd: moment('09:00:00', 'HH:mm:ss'),
    lunchStart: moment('10:00:00', 'HH:mm:ss'),
    lunchEnd: moment('14:30:00', 'HH:mm:ss'),
    dinnerStart: moment('15:00:00', 'HH:mm:ss'),
    dinnerEnd: moment('19:00:00', 'HH:mm:ss')
  };

  public timeSetting = {
    breakfastStart: moment('06:30:00', 'HH:mm:ss'),
    breakfastEnd: moment('07:15:00', 'HH:mm:ss'),
    lunchStart: moment('11:20:00', 'HH:mm:ss'),
    lunchEnd: moment('12:00:00', 'HH:mm:ss'),
    dinnerStart: moment('16:45:00', 'HH:mm:ss'),
    dinnerEnd: moment('17:30:00', 'HH:mm:ss')
  };

  public timeSettingBack = {
    breakfastStart: moment('06:30:00', 'HH:mm:ss'),
    breakfastEnd: moment('07:15:00', 'HH:mm:ss'),
    lunchStart: moment('11:20:00', 'HH:mm:ss'),
    lunchEnd: moment('12:00:00', 'HH:mm:ss'),
    dinnerStart: moment('16:45:00', 'HH:mm:ss'),
    dinnerEnd: moment('17:30:00', 'HH:mm:ss')
  };


  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 22.323489,
    lng: 114.180036,
  };
  zoom = 17;

  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,


  };


  maker: google.maps.LatLngLiteral[] = [];
  makerOptions: Array<any> = [];

  makers: Array<any> = [];


  /*------------------------------------------
  --------------------------------------------
  moveMap()
  --------------------------------------------
  --------------------------------------------*/
  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  /*------------------------------------------
  --------------------------------------------
  move()
  --------------------------------------------
  --------------------------------------------*/
  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  public Maps!: google.maps.MapsLibrary;
  public Marker!: google.maps.MarkerLibrary;
  public map!: google.maps.Map;
  public pin1: any;
  public markerWithText: any;

  directionDisplay = new google.maps.DirectionsRenderer();
  directionsService = new google.maps.DirectionsService();

  directionsServiceOptions: google.maps.DirectionsRequest = {
    origin: {lat: 22.323489, lng: 114.180036},
    destination: {lat: 22.323489, lng: 114.180036},
    travelMode: google.maps.TravelMode.DRIVING,

  };

  mapRequest: google.maps.DirectionsRequest = {
    origin: {lat: 22.324123867655217, lng: 114.1800794005394},
    destination: {lat: 22.324681576529592, lng: 114.18225292775152},
    travelMode: google.maps.TravelMode.DRIVING,
  };


  async ngOnInit() {


    this.swPush.messages.subscribe((message) => {
      console.log('message', message);

    });

    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY

    });

    this.swPush.notificationClicks.subscribe(({action, notification}) => {
      console.log('action', action);
      console.log('notification', notification);
      notification.title = 'New Alarm!';
      notification.data.onActionClick.default.operation = 'openWindow';
      notification.data.onActionClick.default.url = 'https://www.google.com';
      notification.data.onActionClick.default.title = 'Open Google';

      notification.actions = [
        {action: 'openWindow', title: 'Open Google', icon: 'assets/pin1.png'}
      ];
    });


    this.Maps = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
    this.Marker = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;

    const glyphImg = document.createElement('img');
    glyphImg.src = 'assets/pin1.png';
    this.pin1 = new this.Marker.PinElement({
      glyph: glyphImg,
      glyphColor: 'blue',
    });

    this.markerWithText = new this.Marker.AdvancedMarkerElement({
      position: {lat: 22.323489, lng: 114.180036},
      //     map: map,
      title: 'Hello World!',
      content: this.pin1.element,
    });

    this.getDate = new Date().toLocaleDateString('HongKong').slice(0, 10);
    this.initBox();
    this.getCurrentTime();
    this.createChart();

  }

  initBox() {
    const boxcolor = 'grey';
    this.boxInfo = [];
    for (let i = 0; i < 28; i++) {
      this.boxInfo.push({
        box: this.boxList[i],
        boxcolor: boxcolor,
        lat: 22.323489,
        lng: 114.180036,
        battery_lv: '-',
        charged: '1',
        fontcolor: 'white'
      });
    }
  }

  getData(inDate: any) {
    const refreshmsg = document.getElementById('RefreshMessage');
    let currDateTime: any;
    let currTime: any;
    if (refreshmsg)
      refreshmsg.innerHTML = 'Refreshing....';

    this.data.myData(inDate, inDate, this.router).subscribe((data: SensorDataMap) => {
      this.sensorInfo = [];
      this.alarmPoints = {};
      this.initBox();
      let needPlayAudio = false;
      let needSMS = false;
      for (let [sensorName, sensorDatas] of Object.entries(data)) {
        for (let [index, sensorData] of sensorDatas.entries()) {

          currDateTime = moment((new Date(sensorData.time)));
          currTime = moment(currDateTime.format('HH:mm:ss'), 'HH:mm:ss');
          let sensorDataX = parseFloat(sensorData.x)
          let sensorDataY = parseFloat(sensorData.y)
          let sensorDataZ = parseFloat(sensorData.z)
          let sensorDataLat = parseFloat(sensorData.lat)
          let sensorDataLng = parseFloat(sensorData.lng)
          let sensorDataBattery = parseFloat(sensorData.battery_lv)
          let sensorDataCharged = sensorData.charged === "1"

          // update boxinfo
          if (this.boxList.includes(sensorName)) {

            // update battery and chargerd status
            this.boxInfo[this.boxList.indexOf(sensorName)].battery_lv = sensorData.battery_lv;
            this.boxInfo[this.boxList.indexOf(sensorName)].charged = sensorData.charged;

            // check working zone
            let workingZone = undefined;
            if (currTime.isBetween(this.timeSettingStatus.breakfastStart, this.timeSettingStatus.breakfastEnd) && this.breakfast) {
              workingZone = WorkingTime.BREAKFAST
            } else if (currTime.isBetween(this.timeSettingStatus.lunchStart, this.timeSettingStatus.lunchEnd) && this.lunch) {
              workingZone = WorkingTime.LUNCH
            } else if (currTime.isBetween(this.timeSettingStatus.dinnerStart, this.timeSettingStatus.dinnerEnd) && this.dinner) {
              workingZone = WorkingTime.DINNER
            }

            // algorithm
            if (workingZone) {
              if (this.boxInfo[this.boxList.indexOf(sensorData.cart_id)]?.boxcolor !== 'red') {
                this.boxInfo[this.boxList.indexOf(sensorName)].boxcolor = 'green';
              }
              if (this.boxList.indexOf(sensorName) >= 0) {
                // Calculate Accelerometer x,y,z to angle
                this.angleX = Math.atan2(sensorDataX, Math.sqrt(sensorDataY * sensorDataY + sensorDataZ * sensorDataZ)) * (180 / Math.PI);
                this.angleY = Math.atan2(sensorDataY, Math.sqrt(sensorDataX * sensorDataX + sensorDataZ * sensorDataZ)) * (180 / Math.PI);
                this.angleZ = Math.atan2(Math.sqrt(sensorDataX * sensorDataX + sensorDataY * sensorDataY), sensorDataZ) * (180 / Math.PI);

                // Another way to calculate the angle
                this.acceleration = Math.sqrt(Math.pow(sensorDataX, 2) + Math.pow(sensorDataY, 2) + Math.pow(sensorDataZ, 2));
                this.minAcceleration = Math.min(this.minAcceleration, this.acceleration);
                this.maxAcceleration = Math.max(this.maxAcceleration, this.acceleration);

                let resObj = {
                  id: sensorData.device_id,
                  device_id: sensorData.device_id,
                  cart_id: sensorData.cart_id,
                  x: sensorDataX,
                  y: sensorDataY,
                  z: sensorDataZ,
                  lat: sensorDataLat,
                  lng: sensorDataLng,
                  time: currDateTime.format('YYYY-MM-DD HH:mm:ss'),
                  truck_id: sensorData.truck_id,
                  battery_lv: sensorDataBattery,
                  charged: sensorDataCharged,
                  acceleration: this.acceleration,
                  maxAcceleration: this.maxAcceleration,
                  minAcceleration: this.minAcceleration,
                  angleX: this.angleX,
                  angleY: this.angleY,
                  angleZ: this.angleZ,
                  workingZone: workingZone
                }
                this.sensorInfo.push(resObj);

                // get window data
                let windowData = getWindowData(sensorDatas, index)
                let dataStdX = Number(std(windowData.map(data => parseFloat(data.x))))
                let dataStdY = Number(std(windowData.map(data => parseFloat(data.y))))

                // When the total acceleration and gravitational acceleration exceeds the dangerous value
                if (Math.abs(this.acceleration) >= this.dangerLv_acc &&
                  Math.abs(sensorDataZ) >= this.dangerLv_z &&
                  Math.abs(dataStdX) >= this.dangerlv_x_std &&
                  Math.abs(dataStdY) >= this.dangerlv_y_std
                ) {
                  // determine occurred during delivery
                  let delivery_diff = currTime.diff(moment(workAroudMap[sensorName][workingZone].delivery, 'HH:mm:ss'))
                  let pickup_diff = currTime.diff(moment(workAroudMap[sensorName][workingZone].pickup, 'HH:mm:ss'))
                  if (Math.abs(pickup_diff) < Math.abs(delivery_diff)) {
                    continue
                  }

                  needPlayAudio = true;
                  needSMS = true;
                  this.boxInfo[this.boxList.indexOf(sensorData.cart_id)].boxcolor = 'red';
                  this.SMSMessage = this.SMSMessage + sensorData.cart_id + " at " + currDateTime.toString();
                  if (this.alarmPoints[sensorName] === undefined) {
                    this.alarmPoints[sensorName] = [resObj];
                  } else {
                    this.alarmPoints[sensorName].push(resObj);
                  }
                }
              }
            }

          }
        }
      }

      // update page watch color
      for (let [index, _] of this.boxInfo.entries()) {
        this.updateSpanColor(this.boxInfo[index].box, this.boxInfo[index].boxcolor);
      }
      if (refreshmsg)
        refreshmsg.innerHTML = '';
      if (needPlayAudio)
        this.playAudio();
      if (needSMS) {
        console.log('SMS');
      }
    });


  }

  updateSpanColor(boxId: string, color: string) {
    const spanElement = document.getElementById(boxId);
    if (spanElement) {
      spanElement.style.backgroundColor = color;
    }
  }

  readResult(boxid: string) {
    this.cart_id = boxid;
    this.sensorData = this.sensorInfo.filter((item) => item.cart_id === boxid);

    // Re-gen line chart
    this.lineChart.data.labels = this.sensorData.map((item) => {
      const date = new Date(item.time.slice(0, 10) + " " + item.time.slice(11, 19));
      return date.toLocaleString('HongKong', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    });

    // Line Chart Angle
    this.lineChartAngle.data.labels = this.sensorData.map((item) => {
      const date = new Date(item.time.slice(0, 10) + " " + item.time.slice(11, 19));
      return date.toLocaleString('HongKong', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    });

    this.lineChart.data.datasets[0].data = this.sensorData.map((item) => item.x);
    this.lineChart.data.datasets[1].data = this.sensorData.map((item) => item.y);
    this.lineChart.data.datasets[2].data = this.sensorData.map((item) => item.z);
    this.lineChart.data.datasets[3].data = this.sensorData.map((item) => item.acceleration);
    this.lineChart.update();
    this.lineChart.render();

    // Line Chart Angle
    this.lineChartAngle.data.datasets[0].data = this.sensorData.map((item) => item.angleX);
    this.lineChartAngle.data.datasets[1].data = this.sensorData.map((item) => item.angleY);
    this.lineChartAngle.data.datasets[2].data = this.sensorData.map((item) => item.angleZ);
    this.lineChartAngle.data.datasets[3].data = this.sensorData.map((item) => item.acceleration);
    this.lineChartAngle.update();
    this.lineChartAngle.render();

    // re-gen map
    this.lat = 22.323489;
    this.lng = 114.180036;
    this.lnglat = [this.lat, this.lng];
    this.center = {
      lat: this.lat,
      lng: this.lng
    };
    this.maker = [];
    this.makers = [];
    this.polyPath = [];

    let currentSensorAlarm = this.alarmPoints[boxid];
    let needShowPath = false;
    if (this.showRoute) {
      this.directionsResults$ = this.mapDirectionsService.route(this.mapRequest).pipe(map((response) => response.result));
    }
    if (currentSensorAlarm) {
      for (let alarmPoint of currentSensorAlarm) {
        let currDateTime = moment((new Date(alarmPoint.time)));
        let currTime = moment(currDateTime.format('HH:mm:ss'), 'HH:mm:ss');

        if ([this.breakfast && alarmPoint.workingZone === WorkingTime.BREAKFAST,
          this.lunch && alarmPoint.workingZone === WorkingTime.LUNCH,
          this.dinner && alarmPoint.workingZone === WorkingTime.DINNER].some(res => res)) {
          const iconImage = document.createElement("img");
          iconImage.src = 'assets/pin1.png';
          this.makers.push({
            position: {
              lat: alarmPoint.lat,
              lng: alarmPoint.lng,
            },
            title: currTime.format('HH:mm:ss').toString(),
            content: this.buildMapMarkerContent(currTime.format('HH:mm:ss').toString()),
          });
          needShowPath = true;
        }
      }
      if (!this.showRoute && needShowPath) {
        this.directionsResults$ = this.mapDirectionsService.route(this.mapRequest).pipe(map((response) => response.result));
      }
    }
  }

  createChart() {
    this.lineChart = new Chart("MyChart", {
      //this denotes tha type of chart
      type: 'line',

      // values on X-Axis
      data: {
        labels: [],
        datasets: [
          {
            label: "x",
            data: "",
            backgroundColor: 'blue'
          },
          {
            label: "y",
            data: "",
            backgroundColor: 'limegreen'
          },
          {
            label: "z",
            data: "",
            backgroundColor: 'black'
          },

          {
            label: "Acceleration",
            data: "",
            backgroundColor: 'red'
          }


        ]
      },
      options: {
        aspectRatio: 2.5,
        events: ['click', 'mousemove'],
        onClick: (e) => {
          this.selectLine(e);
        },
        interaction: {
          mode: 'x'
        }
      }

    });

    this.lineChart.canvas.parentNode.style.height = '50%';
    this.lineChart.canvas.parentNode.style.width = '100%';


    // line chart angle
    this.lineChartAngle = new Chart("MyChartAngle", {
      type: 'line', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: [],
        datasets: [
          {
            label: "Angle X",
            data: "",
            backgroundColor: 'blue'
          },
          {
            label: "Angle Y",
            data: "",
            backgroundColor: 'limegreen'
          },
          {
            label: "Angle Z",
            data: "",
            backgroundColor: 'black'
          },
          {
            label: "Acceleration",
            data: "",
            backgroundColor: 'red'
          }

        ]
      },
      options: {
        aspectRatio: 2.5,
        events: ['click', 'mousemove'],
        onClick: (e) => {
          this.selectLine(e);
        },
        interaction: {
          mode: 'x'
        }
      }
    });

  }


  selectLine(e: any) {
    console.log('e', e);
    const points = getRelativePosition(e, e.chart);
    const dataX = e.chart.scales.x.getValueForPixel(points.x);
    const dataY = e.chart.scales.y.getValueForPixel(points.y);


    console.log('points', points);
    console.log('dataX', dataX);
    console.log('dataY', dataY);
  }

  onDateSelect(event: any) {
    this.getDate = event.year + '-' + event.month.toString().padStart(2, '0') + '-' + event.day.toString().padStart(2, '0');
    this.getData(this.getDate);

  }

  autoRefresh() {
    clearInterval(this.varDate);

    const button = document.getElementById('refreshButton');
    if (button && button.innerHTML === '停止自動刷新') {
      button.innerHTML = '開始自動刷新';
    } else {
      this.varDate = setInterval(() => {
        this.getData(this.getDate);
      }, 60000);

      if (button) {
        button.innerHTML = '停止自動刷新';
      }
    }
  }

  playAudio() {
    let audio = new Audio();
    //Can externalize the variables
    audio.src = "/assets/facebook_messenger.mp3";
    audio.load();
    audio.play();
  }

  onPrint() {
    window.print();
  }

  onDownload() {
    window.open("/api/download", '_blank');
  }

  getCurrentTime() {
    const today = new Date();
    const time = moment(today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(), "HH:mm:ss");

    // check if breakfast time
    if (time.isBetween(this.timeSettingStatus.breakfastStart, this.timeSettingStatus.breakfastEnd)) {
      this.breakfast = 1;
      this.lunch = 0;
      this.dinner = 0;
    }

    // check if lunch-time
    if (time.isBetween(this.timeSettingStatus.lunchStart, this.timeSettingStatus.lunchEnd)) {
      this.breakfast = 0;
      this.lunch = 1;
      this.dinner = 0;
    }

    // check if dinner time
    if (time.isBetween(this.timeSettingStatus.dinnerStart, this.timeSettingStatus.dinnerEnd)) {
      this.breakfast = 0;
      this.lunch = 0;
      this.dinner = 1;
    }

  }

  submitRpt() {
    window.open("/report?fromDateRpt=" +
      this.fromDateRpt!.year + "-" + this.fromDateRpt!.month.toString().padStart(2, "0") + "-" + this.fromDateRpt!.day.toString().padStart(2, "0") +
      "&endDateRpt=" + this.endDateRpt!.year + "-" + this.endDateRpt!.month.toString().padStart(2, "0") + "-" + this.endDateRpt!.day.toString().padStart(2, "0") +
      "&breakfastRpt=" + this.breakfastRpt +
      "&lunchRpt=" + this.lunchRpt +
      "&dinnerRpt=" + this.dinnerRpt +
      "&dayRpt=" + this.dayRpt +
      "&weekRpt=" + this.weekRpt
      , '_blank');

  }

  jumpSelfSearch() {
    window.open("/selfSearch", '_blank');
  }

  buildMapMarkerContent(msg: any) {
    const content = document.createElement('div');
    content.innerHTML = `
      <div>
        <h4>${msg}</h4>
        <img src="assets/pin1.png" alt="pin1" style="width:30px;height:30px;">
      </div>
        `
    return content;
  }

  subscribeNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => {
        console.log('Notification Subscription: ', sub);
      })
  }


}
