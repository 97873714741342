import {Injectable} from '@angular/core';
import {catchError, map, Observable, tap, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})


export class DataService {
  constructor(private http: HttpClient) {
  }

  data: any;

  public login(username: string, password: string): Observable<any> {
    let options = {
      method: 'post',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding',
        'Access-Control-Max-Age': '3600',
        'Access-Control-Allow-Credentials': 'true',
      }
    };

    const body = {
      username: username,
      password: password
    }

    return this.http.post('/api/auth/login', body, options)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return error.error;
        })
      );
  }


  public sendSMS(message: string, contactList: string): Observable<any> {

    let options = {
      mode: 'cors', method: 'post', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding',
        'Access-Control-Max-Age': '3600',
        'Access-Control-Allow-Credentials': 'true',
      }
    };

    let body = {
      SMSContent: message,
      SMSRecipients: contactList
    };

    return this.http
      .post<any>('/api/sendSms', body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  public myData(startDate: any, endDate: any, router: any): Observable<any> {
    let options = {
      mode: 'cors', method: 'post', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding',
        'Access-Control-Max-Age': '3600',
        'Access-Control-Allow-Credentials': 'true',
      }
    };

    let body = {
      startDate: startDate,
      endDate: endDate
    }

    return this.http
      .post<any>('/api/mydata', body, options)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((err:any) => {
          if (err.status === 401) {
            alert("Access timed out, please log in again.");
            router.navigate(['/']);
          }
          return throwError(err);
        })
      );
  }

  public getData(startTime: any, endTime: any, cartID: any, router: any): Observable<any> {
    let options = {
      mode: 'cors', method: 'post', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding',
        'Access-Control-Max-Age': '3600',
        'Access-Control-Allow-Credentials': 'true',
      }
    };

    let body = {
      startTime: startTime,
      endTime: endTime,
      cartID: cartID
    }

    return this.http
      .post<any>('/api/getdata', body, options)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((err:any) => {
          if (err.status === 401) {
            alert("Access timed out, please log in again.");
            router.navigate(['/']);
          }
          return throwError(err);
        })
      );
  }
}
