export interface SensorInfo {
  id: string;
  device_id: string;
  cart_id: string;
  x: number;
  y: number;
  z: number;
  lat: number;
  lng: number;
  time: any;
  truck_id: string;
  battery_lv: number;
  charged: boolean;
  acceleration: number;
  maxAcceleration: number;
  minAcceleration: number;
  angleX: number;
  angleY: number;
  angleZ: number;
  workingZone: WorkingTime;
}

export enum WorkingTime {
  BREAKFAST = "breakfast",
  LUNCH = "lunch",
  DINNER = "dinner",
  NONE = "other"
}

export interface SensorData {
  device_id: string,
  cart_id: string,
  truck_id: string,
  x: string,
  y: string,
  z: string,
  lat: string,
  lng: string,
  time: string,
  battery_lv: string,
  charged: string
}

export interface SensorDataMap {
  [sensorName: string]: SensorData[];
}
