


<!-- FILEPATH: /Users/franng/dev/time-medical/kh/kh-admin/src/app/app.component.html -->
<!-- BEGIN: abpxx6d04wxr -->

<!-- END: abpxx6d04wxr -->

<!-- FILEPATH: /Users/franng/dev/time-medical/kh/kh-admin/src/app/app.component.html -->
<!-- BEGIN: be15d9bcejpp -->
<!-- Resources -->
<router-outlet></router-outlet>
<!-- END: be15d9bcejpp -->
