import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {DataService} from '../common/data.service';
import {Chart} from "chart.js/auto";
import * as moment from "moment/moment";
import {SensorDataMap, SensorInfo, WorkingTime} from "../common/model";


@Component({
  selector: 'self-search',
  templateUrl: './self-search.component.html',
  styleUrls: ['./self-search.component.scss']
})


export class SelfSearchComponent implements OnInit {
  dateRangeForm: FormGroup;

  public lineChart: any = null;
  public lineChartAngle: any = null;
  public searchInfo: Array<SensorInfo> = [];

  acceleration = 0.00;
  minAcceleration = 0.00;
  maxAcceleration = 0.00;
  cart_id = "";

  constructor(
    private route: ActivatedRoute,
    private data: DataService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.dateRangeForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      cartID: ['', Validators.required]
    });
  }


  ngOnInit() {
    this.createChart();
  }

  onSubmit() {
    if (this.dateRangeForm.valid) {
      const formValue = this.dateRangeForm.value;
      const startDateTime = new Date(`${formValue.startDate}T${formValue.startTime}`);
      const endDateTime = new Date(`${formValue.endDate}T${formValue.endTime}`);
      const timeDiff = endDateTime.getTime() - startDateTime.getTime();
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays < 0) {
        alert('Start time must be earilier than end time.');
        return;
      }
      if (diffDays > 1) {
        alert('Time range cannot exceed one day.');
        return;
      }

      const cartID = formValue.cartID;
      this.cart_id = cartID.concat(" - ");

      this.data.getData(startDateTime, endDateTime, cartID, this.router).subscribe((data: SensorDataMap) => {
        this.searchInfo = [];
        for (let [_, sensorDatas] of Object.entries(data)) {
          for (let [_, sensorData] of sensorDatas.entries()) {
            let currDateTime = moment((new Date(sensorData.time)));
            let sensorDataX = parseFloat(sensorData.x)
            let sensorDataY = parseFloat(sensorData.y)
            let sensorDataZ = parseFloat(sensorData.z)
            let sensorDataLat = parseFloat(sensorData.lat)
            let sensorDataLng = parseFloat(sensorData.lng)
            let sensorDataBattery = parseFloat(sensorData.battery_lv)
            let sensorDataCharged = sensorData.charged === "1"

            // Calculate Accelerometer x,y,z to angle
            let angleX = Math.atan2(sensorDataX, Math.sqrt(sensorDataY * sensorDataY + sensorDataZ * sensorDataZ)) * (180 / Math.PI);
            let angleY = Math.atan2(sensorDataY, Math.sqrt(sensorDataX * sensorDataX + sensorDataZ * sensorDataZ)) * (180 / Math.PI);
            let angleZ = Math.atan2(Math.sqrt(sensorDataX * sensorDataX + sensorDataY * sensorDataY), sensorDataZ) * (180 / Math.PI);

            // Another way to calculate the angle
            this.acceleration = Math.sqrt(Math.pow(sensorDataX, 2) + Math.pow(sensorDataY, 2) + Math.pow(sensorDataZ, 2));
            this.minAcceleration = Math.min(this.minAcceleration, this.acceleration);
            this.maxAcceleration = Math.max(this.maxAcceleration, this.acceleration);

            let resObj = {
              id: sensorData.device_id,
              device_id: sensorData.device_id,
              cart_id: sensorData.cart_id,
              x: sensorDataX,
              y: sensorDataY,
              z: sensorDataZ,
              lat: sensorDataLat,
              lng: sensorDataLng,
              time: currDateTime.format('YYYY-MM-DD HH:mm:ss'),
              truck_id: sensorData.truck_id,
              battery_lv: sensorDataBattery,
              charged: sensorDataCharged,
              acceleration: this.acceleration,
              maxAcceleration: this.maxAcceleration,
              minAcceleration: this.minAcceleration,
              angleX: angleX,
              angleY: angleY,
              angleZ: angleZ,
              workingZone: WorkingTime.NONE
            }
            this.searchInfo.push(resObj);
          }
        }
        this.readResult();
      });
    }
  }

  createChart() {
    this.lineChart = new Chart("MyChart", {
      //this denotes tha type of chart
      type: 'line',

      // values on X-Axis
      data: {
        labels: [],
        datasets: [
          {
            label: "x",
            data: "",
            backgroundColor: 'blue'
          },
          {
            label: "y",
            data: "",
            backgroundColor: 'limegreen'
          },
          {
            label: "z",
            data: "",
            backgroundColor: 'black'
          },

          {
            label: "Acceleration",
            data: "",
            backgroundColor: 'red'
          }


        ]
      },
      options: {
        aspectRatio: 2.5,
        events: ['click', 'mousemove'],
        onClick: () => {
        },
        interaction: {
          mode: 'x'
        }
      }

    });

    this.lineChart.canvas.parentNode.style.height = '50%';
    this.lineChart.canvas.parentNode.style.width = '100%';


    // line chart angle
    this.lineChartAngle = new Chart("MyChartAngle", {
      type: 'line', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: [],
        datasets: [
          {
            label: "Angle X",
            data: "",
            backgroundColor: 'blue'
          },
          {
            label: "Angle Y",
            data: "",
            backgroundColor: 'limegreen'
          },
          {
            label: "Angle Z",
            data: "",
            backgroundColor: 'black'
          },
          {
            label: "Acceleration",
            data: "",
            backgroundColor: 'red'
          }

        ]
      },
      options: {
        aspectRatio: 2.5,
        events: ['click', 'mousemove'],
        onClick: () => {
        },
        interaction: {
          mode: 'x'
        }
      }
    });
  }

  readResult() {
    // Re-gen line chart
    this.lineChart.data.labels = this.searchInfo.map((item) => {
      const date = new Date(item.time.slice(0, 10) + " " + item.time.slice(11, 19));
      return date.toLocaleString('HongKong', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    });

    // Line Chart Angle
    this.lineChartAngle.data.labels = this.searchInfo.map((item) => {
      const date = new Date(item.time.slice(0, 10) + " " + item.time.slice(11, 19));
      return date.toLocaleString('HongKong', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    });

    this.lineChart.data.datasets[0].data = this.searchInfo.map((item) => item.x);
    this.lineChart.data.datasets[1].data = this.searchInfo.map((item) => item.y);
    this.lineChart.data.datasets[2].data = this.searchInfo.map((item) => item.z);
    this.lineChart.data.datasets[3].data = this.searchInfo.map((item) => item.acceleration);
    this.lineChart.update();
    this.lineChart.render();

    // Line Chart Angle
    this.lineChartAngle.data.datasets[0].data = this.searchInfo.map((item) => item.angleX);
    this.lineChartAngle.data.datasets[1].data = this.searchInfo.map((item) => item.angleY);
    this.lineChartAngle.data.datasets[2].data = this.searchInfo.map((item) => item.angleZ);
    this.lineChartAngle.data.datasets[3].data = this.searchInfo.map((item) => item.acceleration);
    this.lineChartAngle.update();
    this.lineChartAngle.render();
  }

}
