/**
 * get message from yee-lam CYL723@ha.org.hk
 *
 *
 * | WARDS            | Breakfast                        |                                  | Lunch                            |                                  | Dinner                           |                                  |
 * |------------------|----------------------------------|----------------------------------|----------------------------------|----------------------------------|----------------------------------|----------------------------------|
 * |                  | Kitchen loading bay to RB Bridge | RB Bridge to Kitchen loading bay | Kitchen loading bay to RB Bridge | RB Bridge to Kitchen loading bay | Kitchen loading bay to RB Bridge | RB Bridge to Kitchen loading bay |
 * | 4A1,4A2,4B1,4B2, | 06:35-06:50                      | 08:20-08:40                      | 11:25-11:40                      | 13:20-13:40                      | 16:45-17:00                      | 18:20-18:30                      |
 * | 5A1,5A2,5B1,5B2  |                                  |                                  |                                  |                                  |                                  |                                  |
 * | 2A1,2A2,2B1,2B2, | 06:55-07:10                      | 08:45-09:00                      | 11:45-12:00                      | 13:45-14:00                      | 17:05-17:20                      | 19:10-19:20                      |
 * | 3A1,3A2,3B1,3B2  |                                  |                                  |                                  |
 */
export const workAroudMap:
  {
    [key: string]: {
      [key: string]: { delivery: string, pickup: string }
    }
  } = {
  '4A1': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '4A2': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '4B1': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '4B2': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '5A1': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '5A2': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '5B1': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '5B2': {
    breakfast: {delivery: '06:50:00', pickup: '08:20:00'},
    lunch: {delivery: '11:40:00', pickup: '13:20:00'},
    dinner: {delivery: '17:00:00', pickup: '18:20:00'}
  },
  '2A1': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  },
  '2A2': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  },
  '2B1': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  },
  '2B2': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  },
  '3A1': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  },
  '3A2': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  },
  '3B1': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  },
  '3B2': {
    breakfast: {delivery: '07:10:00', pickup: '08:45:00'},
    lunch: {delivery: '12:00:00', pickup: '13:45:00'},
    dinner: {delivery: '17:20:00', pickup: '19:10:00'}
  }
};
