import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../common/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor( private router: Router, private data: DataService) { }

  username: string = '';
  password: string = '';


  login() {
    console.log('login',this.username,this.password);

    this.data.login(this.username,this.password).subscribe(
      (data: any) => {
      console.log('login',data);
      if (data.error==null) {
        console.log('login',data);
      //  this.data.setToken(data.token);
        this.router.navigate(['/sensor-status']);
      } else {
        alert('Login failed');
      }
    },
    (error)=> {
      console.log('login error',error);
      alert('Login failed');
    })

  }

}
