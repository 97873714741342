import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GmapComponent implements OnInit {
  @Input() public boxid!: string;
  @Input() public lng!: number;
  @Input() public lat!: number;
  @Input() public lnglat!: Array<number>;


    display: any;
    center: google.maps.LatLngLiteral = {
        lat: 22.323489,
        lng: 114.180036,
    };
    zoom = 17;

    options: google.maps.MapOptions = {
      mapTypeId: 'hybrid',
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      maxZoom: 20,
      minZoom: 8,
      

    };

    title="B003";

    maker: google.maps.LatLngLiteral[] = [
      {
        lat: 22.323489,
        lng: 114.181036,
      },
      {
        lat: 22.323489,
        lng: 114.182036,
      },
      {
        lat: 22.323489,
        lng: 114.183036,
      },
      {
        lat: 22.323489,
        lng: 114.184036,
      },
      {
        lat: 22.323489,
        lng: 114.185036,
      },
      {
        lat: 22.323489,
        lng: 114.186036,
      },
      {
        lat: 22.323489,
        lng: 114.187036,
      },
      {
        lat: 22.323489,
        lng: 114.188036,
      },
      {
        lat: 22.323489,
        lng: 114.189036,
      },
    ]
  
    /*------------------------------------------
    --------------------------------------------
    moveMap()
    --------------------------------------------
    --------------------------------------------*/
    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.center = (event.latLng.toJSON());
    }
  
    /*------------------------------------------
    --------------------------------------------
    move()
    --------------------------------------------
    --------------------------------------------*/
      move(event: google.maps.MapMouseEvent) {
          if (event.latLng != null) this.display = event.latLng.toJSON();
      }


    ngOnInit(): void {
      console.log("lng:",this.lng);
      console.log("lat:",this.lat);
      // Rest of the code...
    }

}
